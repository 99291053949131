<template>
  <div>
    <div class="fullHeight is-flex is-flex-direction-column is-align-items-center is-justify-content-center" >
      <p class="title is-4 mb-4">Login</p>
      <form @keyup.enter="loginRequest()">
        <b-input placeholder="Email" v-model="email" class="mb-2"></b-input>
        <b-input placeholder="Password" v-model="password" type="password" class="mb-4" password-reveal></b-input>
        <div class="is-flex is-justify-content-center">
          <b-button v-if="loadingLogin" rounded class="is-info is-light" loading>Login</b-button>
          <b-button v-else rounded class="is-info" type="submit" @click="loginRequest()">Login</b-button>
        </div>
      </form>
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-4">
        <p>Don't have an account yet? 
          <router-link to="sign-up">Sign up here.</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { db } from '@/main';

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loadingLogin: false,
    };
  },
  methods: {
    loginRequest() {
      this.loadingLogin = true;
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then((object) => {
          this.loadingLogin = false;
          this.$router.replace("/devices");
           // for tracking loginHistory
          db.collection('users').doc(object.user.uid).update({
            loginHistory: firebase.firestore.FieldValue.arrayUnion({ status: 'login', userAgent: navigator.userAgent, date: new Date,  }),
          });
        })
        .catch((error) => {
          console.log("errorCode: ", error.code);
          console.log("errorMessage: ", error.message);
          // if (errorCode == "auth/user-not-found") {
          //   router.push({ path: "signup" });
          // }
          this.loadingLogin = false;
        });
    }
  }
};
</script>