import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/login',
  }, 
  {
    path: '*',
    redirect: '/login',
  }, 
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/sign-up',
    name: 'Sign Up',
    component: () => {
      return import('@/views/Sign-up.vue')
    },
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => {
      return import('@/views/Dashboard.vue')
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => {
      return import('@/views/Devices.vue')
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/guides',
    name: 'Guides',
    component: () => {
      return import('@/views/Guides.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/steps',
    name: 'Steps',
    component: () => {
      return import('@/views/Steps.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/add',
    name: 'Add Devices',
    component: () => {
      return import('@/views/addDevices.vue')
    },
    meta: {
      requiresAuth: true
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


import firebase from "firebase/app";
import store from '@/store'

// await the response on whether the user is logged in or not
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  })
};
// redirect if not logged in, after the promise above resolves
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await firebase.getCurrentUser()){
    next('login');
  }
  else if ((to.path == '/login' || to.path == '/sign-up') && await firebase.getCurrentUser()) { // don't route to sign-up or login if we're already signed in
    next('devices'); // was dashboard
  }
  else if (to.path == '/dashboard' && !store.state.user.isLoading && !store.state.user.meta.is_owner) { // if the user we're logged into is not an owner
    next('devices')
  }
  else {
    next();
  }
});

export default router