<template>
  <div id="app" class="has-background-white-ter">
    <div v-if="isLoadingLogin">
      <!-- stops the page from flashing to show the login component when refreshing, when logged in -->
      <b-loading :is-full-page="true" v-model="isLoadingLogin" :can-cancel="false"></b-loading>
    </div>
    <div v-else>
      <div v-if="user.loggedIn" id="nav">
        <div v-if="repairStatus==='in-progress'" class="width-100 is-flex is-justify-content-flex-end is-align-items-center leaveButton">
          <b-button @click="leaveRepair()" class="is-danger" rounded icon-right="close-thick">
            <strong>Leave</strong>
          </b-button>
        </div>
        <b-navbar v-else type="is-light" spaced>
          <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img src="@/assets/mouse-logo.png">
            </b-navbar-item>
          </template>
          <template slot="start">
            <b-navbar-item v-if="isOwner" to="/dashboard" tag="router-link">
              Dashboard
            </b-navbar-item>
            <b-navbar-item to="/devices" tag="router-link">
              Devices
            </b-navbar-item>
            <b-navbar-item v-if="user.uid == '5eCyeS9mpFgwLvxMkYvSQI0MmmY2'" to="/add" tag="router-link">
              Add Devices
            </b-navbar-item>
          </template>
          <template slot="end" tag="div">
              <b-button class="is-info is-light" @click="logout()" rounded>
                <strong>Logout</strong>
              </b-button>
          </template>
        </b-navbar>
      </div>

      <div v-if="isLoading">
        <!-- regular loading, on top of router-view -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
      <!-- show the router-view regardless of sign in status -->
      <router-view class="routerView" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { mapGetters } from "vuex";

export default {
  computed: {
    isOwner() {
      return this.user.meta.is_owner
    },
    ...mapGetters({
      user: "getUser",
      isLoadingLogin: "isLoadingLogin",
      isLoading: "isLoading",
      repairStatus: "getRepairStatus",
      repairID: "getRepairID",
      showChecklist: "getShowChecklist"
    })
  },
  methods: {
    logout() {
      firebase.auth().signOut()
        .then(() => {
          sessionStorage.clear();
          this.$store.dispatch("unbindAllFirestore");
          this.$router.push("/login");
        })
        .catch(error => {
          console.log(error);
        });
    },
    back() {
      this.$router.push(-1);
    },
    leaveRepair() {
      this.$buefy.dialog.confirm({
        title: "Quit repair?",
        message: "Are you sure you want to quit this repair? This will be logged.",
        confirmText: "Quit Repair",
        type: "is-danger",
        // hasIcon: true,
        onConfirm: () => {
          this.$buefy.toast.open({ duration: 2200, message: `Repair ended`, position: 'is-top' })
          this.$store.dispatch("leaveRepair");
        }
      });
    }
  }
};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  word-break: break-word;
}

@media only screen and (max-width: 1025px) {
  .routerView {
    padding: 0 8vw;
    // width: 100%;
    height: 100vh !important;
    width: 100vw !important;
  }
}

#nav {
  font-weight: bold;
  color: #2c3e50;
  .router-link-exact-active {
    color: #42b983;
    // text-decoration: underline;
  }
}
// these 2 set the height of the navbar on mobile
a.navbar-burger.burger {
  height: 10vh!important;
}
.leaveButton {
  height: 10vh!important;
  padding-right: 2rem;
}

.width-100 {
  width: 100%!important;
}

.fullScreen {
  height: 90vh !important;
  width: 100vw;
  overflow: scroll;
}
.fullWidth {
  width: 100vw;
}
.fullHeight {
  height: 100vh;
}
.maxWidth {
  width: clamp(310px, 85vw, 1080px);
}
@media only screen and (max-width: 768px) {
  .maxWidth {
    margin: 0 10vw;
  }
}

.boxGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 0.75rem;
}
@media only screen and (max-width: 768px) {
  .boxGrid {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
}
.customBox {
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
}

#scrollEnd {
  height: 10px;
  border-bottom: solid 1px #333;
}

.deviceImage {
  height: 15vh;
}


.mainContent {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  padding: 0 10vw;
  width: 100%;
  max-width: 960px;
}
@media only screen and (max-width: 768px) {
  .mainContent {
    padding: 0;
  }
}

.stepsContainer {
  height: 75vh !important;
  max-height: 75vh !important;
  // overflow: scroll;
}
</style>
