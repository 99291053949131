import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@mdi/font/css/materialdesignicons.css'

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import titleMixin from '@/mixins/titleMixin' // for page title to show in browser
Vue.mixin(titleMixin)

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyAMYmaJEmO4I8ctbkfR_nwExO24_Vq7tEQ",
  authDomain: "repair-advisor-ef12a.firebaseapp.com",
  projectId: "repair-advisor-ef12a",
  storageBucket: "repair-advisor-ef12a.appspot.com",
  messagingSenderId: "893985377462",
  appId: "1:893985377462:web:2a0a983fa55a4bf6f82a17",
  measurementId: "G-PVHCT8GT0T"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

firebase.auth().onAuthStateChanged((user) => {
  let object = {};
  if (user) {
    object.loggedIn = true;
    object.email = user.email;
    object.uid = user.uid;
    store.dispatch('bindDevicesList');
  } else {
    object.loggedIn = false;
    object.email = null;
    object.uid = null;
    store.dispatch('unbindAllFirestore');
  }
  store.dispatch('setLogin', object);
})

export var db = firebase.firestore();

Vue.use(Buefy, {
  defaultTooltipType: 'is-dark',
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  methods: {
    
  },
  render: function (h) { return h(App) }
}).$mount('#app')
