import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import axios from 'axios'

import { vuexfireMutations, firestoreAction } from 'vuexfire'
import createPersistedState from 'vuex-persistedstate'
import { db } from '@/main'

import firebase from 'firebase/app'
import 'firebase/firestore'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    user: {
      loggedIn: false,
      email: null,
      uid: null,
      meta: {},
    },
    isLoadingLogin: true,
    isLoading: true,
    devicesList: [],
    selectedDevice: {},
    guide: null,
    guideLoaded: false,
    repairStatus: null,
    currentStep: 1,
    scrollStatus: false,
    repairID: null,
    repairLog: [],
    showChecklist: {
      value: null,
      status: false,
    },
    showReassembly: false,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getUserData(state) {
      return state.userData;
    },
    getRepairLog(state) {
      return state.repairLog;
    },
    isLoadingLogin(state) {
      return state.isLoadingLogin;
    },
    isLoading(state) {
      return state.isLoading;
    },
    getDevicesList(state) {
      return state.devicesList;
    },
    getSelectedDevice(state) {
      return state.selectedDevice;
    },
    getSelectedGuide(state) {
      return state.guide;
    },
    isGuideLoaded(state) {
      return state.guideLoaded;
    },
    getRepairStatus(state) {
      return state.repairStatus;
    },
    getCurrentStep(state) {
      return state.currentStep;
    },
    getScrollStatus(state) {
      return state.scrollStatus;
    },
    getRepairID(state) {
      return state.repairID;
    },
    getShowChecklist(state) {
      return state.showChecklist;
    },
    getShowReassembly(state) {
      return state.showReassembly;
    }
  },
  mutations: {
    SET_LOGIN_STATUS(state, object) {
      state.user.loggedIn = object.loggedIn;
      state.user.email = object.email;
      state.user.uid = object.uid;
    },
    SET_LOGIN_LOADING(state, value) {
      state.isLoadingLogin = value;
    },
    SET_USER_DATA(state, object) {
      state.user.meta = object;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SELECT_DEVICE(state, object) {
      state.selectedDevice = object;
    },
    SET_GUIDE(state, object) {
      state.guide = object;
      if (object) {
        state.guideLoaded = true;
      }
    },
    SET_REPAIR_STATUS(state, value) {
      state.repairStatus = value;
    },
    UPDATE_CURRENT_STEP(state, value) {
      state.currentStep = value;
    },
    SET_SCROLL_STATUS(state, value) {
      state.scrollStatus = value;
    },
    SET_REPAIR_ID(state, value) {
      state.repairID = value;
    },
    SET_SHOW_CHECKLIST(state, object) {
      state.showChecklist = object;
    },
    SHOW_REASSEMBLY(state, value) {
      state.showReassembly = value;
    },

    RESET_STORE(state) {
      const obj = {
        user: {
          loggedIn: false,
          email: null,
          uid: null,
          meta: {},
        },
        isLoadingLogin: true,
        isLoading: true,
        devicesList: [],
        selectedDevice: {},
        guide: null,
        guideLoaded: false,
        repairStatus: null,
        currentStep: 1,
        scrollStatus: false,
        repairID: null,
        repairLog: [],
        showChecklist: {
          value: null,
          status: false,
        },
      }
      Object.keys(obj).forEach(key => {
        state[`${key}`] = obj[key];
      });
    },
    ...vuexfireMutations,
  },
  actions: {
    setLogin({ commit, dispatch }, object) {
      commit('SET_LOGIN_STATUS', object)
      commit('SET_LOGIN_LOADING', false)
      commit("SET_LOADING", true)
      if (object.loggedIn) {
        db.collection('users').doc(object.uid).get()
        .then(snapshot => {
          dispatch("bindRepairLog")
          commit("SET_USER_DATA", snapshot.data())
          commit("SET_LOADING", false) // if we are logged in, we don't wanna stop loading til we get the user's meta info
          commit('SET_LOGIN_LOADING', false)
        })
        .catch(err => {
          console.log('error fetching the document, peepee poopoo')
          console.error(err)
        })
      } else {
        commit("SET_LOADING", false)
      }
    },
    selectDevice({ commit }, object) {
      commit('SELECT_DEVICE', object)
      commit('UPDATE_CURRENT_STEP', 1) // reset the steps. since they are persisting
      commit("SET_REPAIR_ID", null)
      router.push('guides')
    },
    getGuide({ commit }, guide) {
      commit("SET_LOADING", true)
      commit("SET_REPAIR_STATUS", 'parts-and-tools')
      axios.get(`https://www.ifixit.com/api/2.0/guides/${guide}`)
        .then((res) => {
          commit('SET_GUIDE', res.data)
          router.push('steps')
          commit("SET_LOADING", false)
        })
        .catch(() => {
          window.alert('Error occurred fetching the guide');
          console.log('error when fetching the guide. try again')
          commit("SET_LOADING", false);
        })
    },
    updateRepairStatus({commit}, object) {
      const ref = db.collection('shops').doc(this.state.user.meta.shop_id).collection('repairLog');
      if (object.status) { // write regardless of a previous ID, we locally keep the status for use in rendering content in the guide
        commit('SET_REPAIR_STATUS', object.status);
      }
      if (!this.state.repairID) { // if the store has no repairID already, we must create and set one
        let toSend = object;
        toSend.tech = this.state.user.meta.name;
        toSend.uid = this.state.user.uid;
        toSend.notes = [];
        toSend.createdAt = new Date();
        ref.add(toSend)
        .then((docRef) => {
          commit("SET_REPAIR_ID", docRef.id)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
      } else {
        let updateToSend = {};
        if (object['after-repair']) {
          updateToSend['after-repair'] = object['after-repair'];
        }
        updateToSend.progress = firebase.firestore.FieldValue.arrayUnion(object.progress);
        ref.doc(this.state.repairID).update(updateToSend);
      }
    },
    updateCurrentStep({ commit }, value) {
      console.log('updating step')
      let bool = 0; // very inefficient way of doing math, but the temp variable must be used due to the way action's use store data
      if (value === true) {
        bool = this.state.currentStep + 1;
      } else {
        bool = this.state.currentStep - 1;
      }
      db.collection('shops').doc(this.state.user.meta.shop_id).collection('repairLog').doc(this.state.repairID).update({
        'currentStep': bool,
      });
      commit('UPDATE_CURRENT_STEP', bool)
    },
    leaveRepair({ commit }) { // for quitting a repair, without finishing it
      db.collection('shops').doc(this.state.user.meta.shop_id).collection('repairLog').doc(this.state.repairID).update({
        progress: firebase.firestore.FieldValue.arrayUnion({ status: 'quit-repair', date: new Date }),
      });
      commit('SET_REPAIR_STATUS', null)
      commit("SET_REPAIR_ID", null)
      router.push('devices')
    },

    bindDevicesList: firestoreAction(({ bindFirestoreRef }) => {
      const options = { wait: true }
      return bindFirestoreRef('devicesList', db.collection('devices').orderBy('index'), options);
    }),
    bindRepairLog: firestoreAction(({ bindFirestoreRef, state }) => {
      const options = { wait: true }
      return bindFirestoreRef('repairLog', db.collection('shops').doc(state.user.uid).collection('repairLog').orderBy('createdAt', 'desc'), options);
    }),
    unbindAllFirestore: firestoreAction(({ unbindFirestoreRef, commit }) => {
      unbindFirestoreRef('devicesList');
      unbindFirestoreRef('repairLog');
      commit("RESET_STORE");
    }),
  },
  modules: {
  }
})
